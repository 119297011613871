<template>
  <div class="tw-flex tw-h-screen tw-overflow-hidden">
    <t-side-nav :navItems="navItems">
      <template v-slot:dropdown="{ isClosed }" v-if="userAccountList">
        <t-nav-dropdown
          icon="cl-icon-tabler-users"
          data-test-id="navbar-msp-select"
          @selected="customerSelected"
          :isClosed="isClosed"
          :loading="fetchingCustomers"
          :options="userAccountList"
          :initial-selected="initialCustomer"
          @clicked="clickedEvent"
        />
      </template>
    </t-side-nav>
    <div
      class="tw-ml-14 tw-flex tw-flex-1 tw-flex-col tw-overflow-y-auto tw-bg-light lg:tw-ml-0"
    >
      <main>
        <div class="container-fluid">
          <t-page-header :user="user" :user-links="userLinks" />
          <router-view />
        </div>
      </main>
      <cl-footer
        class="tw-mt-auto"
        v-if="logo"
        :version="appVersion"
        :logo="logo"
      />
      <div v-else role="status" class="tw-animate-pulse">
        <div
          class="tw-bg-gray-20 tw-h-20 tw-w-full tw-bg-dorian-gray-100"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { version } from "../../../package";
import TSideNav from "@/components/TSideNav";
import TPageHeader from "@/components/TPageHeader";
import TNavDropdown from "@/components/TNavDropdown";
import { useSort } from "@/composables/useSort";

import {
  ACCOUNT_TYPE,
  HELPDESK,
  SPAM_CONFIG,
  WEB_CONFIG,
  SAFE_CONFIG,
  ARC_CONFIG,
  PRODUCTS,
  USER_LINKS,
  PHISH_CONFIG,
} from "@/constants";

export default {
  components: {
    TSideNav,
    TPageHeader,
    TNavDropdown,
  },
  name: "MspLayout",
  data() {
    return {
      label: "Customers",
      hasIcon: false,
      initialCustomer: null,
      userAccountList: [
        { name: "All Customers", short: "AC", id: 0, allCustomers: true },
      ],
      navItems: this.$getPrimaryNavItems("msp"),
      selected: null,
      availableOptions: null,
      open: false,
      appVersion: version,
      isDropdownOpen: false,
      checkFetchFullList: false,
    };
  },
  watch: {
    userAccountList() {
      this.setSelectedUser();
    },
    mspCustomers: {
      handler() {
        // ones we fetch full list after we do only sorting
        if (!this.checkFetchFullList) {
          this.userAccountList = this.cleanCustomerAccounts(this.mspCustomers);
        } else {
          const { applySorting } = useSort(
            this.currentSortedBy,
            this.currentSortedDir
          );
          this.userAccountList = applySorting(this.userAccountList);
        }
      },
      immediate: true,
      deep: true,
    },
    serverTotal() {
      this.isDropdownOpen = false;
      this.checkFetchFullList = false;
    },
  },
  computed: {
    ...mapGetters("authentication", [
      "selectedUser",
      "authUser",
      "selectedAccount",
      "selectedMsp",
    ]),
    ...mapGetters("support", ["userLinks"]),
    ...mapGetters("userInterface", ["interface", "uiLogo"]),
    ...mapGetters("msps", [
      "mspCustomers",
      "fetchingCustomers",
      "currentSortedBy",
      "currentSortedDir",
      "serverTotal",
    ]),
    user() {
      const email = this.authUser?.email ?? null;
      const accountname = this.authUser?.accountname ?? null;

      return {
        email,
        accountname,
      };
    },
    logo() {
      return this.uiLogo;
    },
  },
  methods: {
    ...mapActions("support", ["setUserLinks"]),
    ...mapActions("userInterface", ["fetchInterface"]),
    ...mapActions("authentication", ["setCustomer"]),
    ...mapActions("pageMeta", ["setParentPath"]),
    ...mapActions("msps", [
      "fetchMspCustomers",
      "clearList",
      "fetchAllMspCustomers",
    ]),
    ...mapActions("emailsHistory", ["clearSearch"]),
    async clickedEvent() {
      try {
        if (!this.isDropdownOpen && !this.checkFetchFullList) {
          const list = await this.fetchAllMspCustomers({
            mspId: this.selectedMsp.id,
          });
          this.userAccountList = this.cleanCustomerAccounts(list.data);
          this.isDropdownOpen = true;
        }
      } catch (_err) {
        // stub
      } finally {
        if (this.serverTotal === this.userAccountList.length - 2) {
          this.checkFetchFullList = true;
        }
      }
    },
    setSelectedUser() {
      this.initialCustomer = 0;
    },
    cleanCustomerAccounts(accounts) {
      const { applySorting } = useSort(
        this.currentSortedBy,
        this.currentSortedDir
      );
      const allAccounts = applySorting(accounts);

      let otherAccounts = [
        { name: "All Customers", short: "AC", id: 0, allCustomers: true },
      ];
      if (this.selectedAccount?.account_type === ACCOUNT_TYPE.SYSTEM) {
        otherAccounts.push({
          name: "All MSPs",
          short: "AM",
          allMsps: true,
          allCustomers: false,
        });
      }
      return [...otherAccounts, ...allAccounts];
    },
    async customerSelected(customer) {
      this.setCustomer(customer);
      if (customer.allMsps) {
        this.$router.push("/system/overview", undefined, { shallow: true });
      } else if (customer.allCustomers) {
        this.$router.push("/msp/overview", undefined, { shallow: true });
      } else if (this.$route.path !== "/customer/overview") {
        this.$router.push("/customer/overview", undefined, { shallow: true });
      }
    },
  },
  beforeMount() {
    let parentPath = "";
    if (this.selectedAccount?.account_type === ACCOUNT_TYPE.SYSTEM) {
      parentPath += "/system";
    }
    this.setParentPath(parentPath);
  },
  async mounted() {
    try {
      this.clearList();

      await this.fetchMspCustomers({
        mspId: this.selectedMsp.id,
      });
    } catch (_err) {
      // stub
    }
  },
  created() {
    if (this.selectedAccount?.account_type === ACCOUNT_TYPE.SYSTEM) {
      let userAccountListItemForSystem = {
        name: "All MSPs",
        short: "AM",
        allMsps: true,
        allCustomers: false,
      };
      this.userAccountList.push(userAccountListItemForSystem);
    }
    const userLinksValue = [
      {
        ...USER_LINKS,
        title: this.$t(PRODUCTS.userLinksTitle),
      },
      {
        title: this.$t("Support"),
        link: HELPDESK.link,
        icon: "cl-icon-tabler-lifebuoy",
      },
    ];
    this.setUserLinks(userLinksValue);
  },
  beforeUnmount() {
    this.clearSearch();
  },
};
</script>
