import { $http } from "@/plugins/api.js";

const getDefaultState = () => {
  return {
    domains: [],
    fetchingDomains: false,
    testEmail: null,
    currentSortedBy: null,
    currentSortedDir: null,
    fetching: false,
  };
};

// initial state
const state = getDefaultState();

export const getters = {
  domains: (state) => state.domains,
  fetchingDomains: (state) => state.fetchingDomains,
  serverTotal: (state) => state.serverTotal,
  currentSortedBy: (state) => state.currentSortedBy,
  currentSortedDir: (state) => state.currentSortedDir,
  fetching: (state) => state.fetching,
};

export const actions = {
  clearCache({ commit }) {
    commit("resetState");
  },
  async fetchDomains(
    { commit },
    {
      tier,
      id,
      params = "",
      results = 10,
      page = 1,
      sortBy = "domain",
      sortDir = "desc",
      filter = null,
    }
  ) {
    let requestURL =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/domains`
        : `/restapi/domains`;

    if (results) requestURL += `?results=${results}`;
    if (page) requestURL += `&page=${page}`;
    if (sortBy) requestURL += `&sortkey=${sortBy}`;
    if (sortDir) requestURL += `&sortdir=${sortDir}`;
    if (filter) requestURL += `&filter=${filter}`;
    if (params) requestURL += `&${params}`;

    commit("setFetchingDomains", true);
    const res = await $http.get(requestURL);

    commit("setDomains", res.data.data);
    commit("setFetchingDomains", false);
    commit("setServerTotal", res.data.total);
    commit("setCurrentSortedBy", sortBy);
    commit("setCurrentSortedDir", sortDir);
    return res.data.data;
  },
  async fetchAllDomains({ state, commit }, { tier, id }) {
    commit("setFetching", true);
    let requestURL =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/domains`
        : `/restapi/domains`;
    requestURL += `?sortkey=${state.currentSortedBy}&sortdir=${state.currentSortedDir}`;
    const res = await $http.get(requestURL);
    commit("setFetching", false);
    return res.data;
  },

  async fetchDomainDetails(_context, { tier, id, domainId }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/domains/${domainId}`
        : `/restapi/domains/${domainId}`;
    const res = await $http.get(url);

    return res.data;
  },

  async createDomain({ commit }, { tier, id, formData }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/domains`
        : `/restapi/domains`;
    const res = await $http.post(url, formData);

    commit("addDomain", res.data);
    return res.data;
  },

  async updateDomain(_context, { tier, id, formData, domainId }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/domains/${domainId}`
        : `/restapi/domains/${domainId}`;
    const res = await $http.put(url, formData);
    return res;
  },

  async batchDeleteDomains({ commit }, { domains, tier, id }) {
    const entries = [];

    for (const domain of domains) {
      const key = `${domain.id}`;
      const value = {
        method: "delete",
        path:
          tier !== null && id !== null
            ? `${tier}/${id}/domains/${domain.id}`
            : `/domains/${domain.id}`,
      };
      entries.push([key, value]);
    }

    commit("setFetchingDomains", true);

    const res = await $http.post("/restapi/batch", Object.fromEntries(entries));
    const ids = Object.keys(res.data).map((item) => +item);

    commit("deleteDomains", ids);
    commit("setFetchingDomains", false);
  },
  async sendTestEmail({ commit }, { dataValue }) {
    const res = await $http.post("/restapi/tools/test/email", dataValue);

    commit("setTestEmail", res.data);
  },
};

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setFetchingDomains: (state, data) => (state.fetchingDomains = data),
  setDomains: (state, data) => (state.domains = data),
  addDomain: (state, data) => {
    state.domains.push(data);
    state.serverTotal = state.serverTotal + 1;
  },
  updateDomain: (state, data) => {
    const index = state.domains.findIndex(
      (domain) => Number(domain.id) !== Number(data.idx)
    );

    if (index !== -1) {
      state.domains[index] = data;
    }
  },
  deleteDomains(state, ids) {
    for (const id of ids) {
      state.domains = state.domains.filter(
        (domain) => Number(domain.id) !== Number(id)
      );
      state.serverTotal = state.serverTotal - 1;
    }
  },
  setTestEmail: (state, payload) => (state.testEmail = payload),
  setServerTotal: (state, payload) => (state.serverTotal = payload),
  setCurrentSortedBy: (state, payload) => (state.currentSortedBy = payload),
  setCurrentSortedDir: (state, payload) => (state.currentSortedDir = payload),
  setFetching: (state, payload) => (state.fetching = payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
