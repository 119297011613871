<template>
  <div class="tw-flex tw-h-screen tw-overflow-hidden">
    <t-side-nav :navItems="navItems">
      <template v-slot:dropdown="{ isClosed }" v-if="domainsList">
        <t-nav-dropdown
          icon="cl-icon-tabler-users"
          data-test-id="navbar-customer-select"
          @selected="domainSelected"
          :isClosed="isClosed"
          :loading="fetchingDomains"
          :options="domainsList"
          @clicked="clickedEvent"
        />
      </template>
    </t-side-nav>

    <div
      class="tw-ml-14 tw-flex tw-flex-1 tw-flex-col tw-overflow-y-auto tw-bg-light lg:tw-ml-0"
    >
      <main class="tw-mb-6">
        <div class="container-fluid">
          <t-page-header :user="user" :user-links="userLinks" />
          <router-view />
        </div>
      </main>
      <cl-footer
        class="tw-mt-auto"
        v-if="logo"
        :version="appVersion"
        :logo="logo"
      />
      <div v-else role="status" class="tw-animate-pulse">
        <div
          class="tw-bg-gray-20 tw-h-20 tw-w-full tw-bg-dorian-gray-100"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { version } from "../../../package";

import TSideNav from "@/components/TSideNav";
import TPageHeader from "@/components/TPageHeader";
import { ACCOUNT_TYPE, TIER, USER_LINKS, PRODUCTS } from "@/constants";
import { setSupportLinks } from "@/utils/set-layout-support.js";
import TNavDropdown from "@/components/TNavDropdown";
import { useSort } from "@/composables/useSort";

export default {
  name: "CustomerLayout",
  components: {
    TSideNav,
    TPageHeader,
    TNavDropdown,
  },
  data() {
    return {
      breakpointXL: null,
      domainsList: [
        { name: "All Domains", short: "AD", id: 0, allCustomers: true },
        { name: "All Customers", short: "AC", id: 0, allCustomers: true },
      ],
      account_type: ACCOUNT_TYPE.CUSTOMER,
      navItems: this.$getPrimaryNavItems("customer_admin"),
      appVersion: version,
      tierType: TIER.domainGroups,
      isDropdownOpen: false,
      checkFetchFullList: false,
    };
  },
  computed: {
    ...mapGetters("authentication", [
      "selectedAccount",
      "selectedCustomer",
      "authUser",
    ]),
    ...mapGetters("domains", [
      "domains",
      "fetchingDomains",
      "currentSortedBy",
      "currentSortedDir",
      "serverTotal",
    ]),
    ...mapGetters("support", ["support", "userLinks"]),
    ...mapGetters("userInterface", ["interface", "uiLogo"]),
    user() {
      const email = this.authUser?.email ?? null;
      const accountname = this.authUser?.accountname ?? null;

      return {
        email,
        accountname,
      };
    },
    logo() {
      return this.uiLogo;
    },
  },
  watch: {
    domains: {
      handler() {
        // ones we fetch full list after we do only sorting
        if (!this.checkFetchFullList) {
          this.domainsList = this.cleanDomains(this.domains);
        } else {
          const { applySorting } = useSort(
            this.currentSortedBy,
            this.currentSortedDir
          );
          this.userAccountList = applySorting(this.domainsList);
        }
      },
      immediate: true,
      deep: true,
    },
    support: {
      handler(support) {
        const res = setSupportLinks(support);
        const resWithTranslatedTitle = res.map((t) => ({
          ...t,
          title: this.$t(t.title),
        }));
        this.updateUserLinks(resWithTranslatedTitle);
      },
      immediate: true,
    },
    serverTotal() {
      this.isDropdownOpen = false;
      this.checkFetchFullList = false;
    },
  },
  methods: {
    ...mapActions("authentication", ["setDomain"]),
    ...mapActions("domains", ["clearCache", "fetchDomains", "fetchAllDomains"]),
    ...mapActions("userInterface", ["fetchInterface"]),
    ...mapActions("pageMeta", ["setParentPath"]),
    ...mapActions("support", [
      "fetchSupport",
      "setUserLinks",
      "updateUserLinks",
    ]),
    ...mapActions("emailsHistory", ["clearSearch"]),
    async clickedEvent() {
      try {
        if (!this.isDropdownOpen) {
          const list = await this.fetchAllDomains({
            id: this.selectedCustomer.id,
            tier: this.tierType,
          });
          this.domainsList = this.cleanDomains(list.data);
          this.isDropdownOpen = true;
        }
      } catch (_err) {
        // stub
      } finally {
        if (this.serverTotal === this.domainsList.length - 2) {
          this.checkFetchFullList = true;
        }
      }
    },
    async fetchSupportData() {
      try {
        await this.fetchSupport({
          type_id: this.selectedCustomer.id,
          type: this.tierType,
        });
      } catch (_err) {
        // stub
      }
    },

    async fetchNewDomains() {
      try {
        this.clearCache();
        this.fetching = true;

        await this.fetchDomains({
          id: this.selectedCustomer.id,
          tier: this.tierType,
        });
      } catch (_err) {
        // stub
      }
    },

    cleanDomains(domains) {
      const modifyDomains = domains.map((dm) => {
        return {
          ...dm,
          name: dm.domain,
          testID: `navbar-domain-select-${dm.domain
            .toLowerCase()
            .replace(/\s/g, "-")}`,
          allDomains: false,
          short: this.$getAccountShortName(dm.domain),
        };
      });
      const { applySorting } = useSort(
        this.currentSortedBy,
        this.currentSortedDir
      );
      const allDomains = applySorting(modifyDomains);
      let otherAccounts = [
        { name: "All Domains", short: "AD", id: 0, allCustomers: false },
      ];
      if (this.selectedAccount?.account_type <= ACCOUNT_TYPE.MSP) {
        otherAccounts.push({
          name: "All Customers",
          short: "AC",
          allDomains: true,
          allCustomers: true,
        });
      }
      return [...otherAccounts, ...allDomains];
    },
    async domainSelected(domain) {
      this.setDomain(domain);
      if (domain.allCustomers) {
        await this.$router.push("/msp/overview");
      } else if (this.$route.path !== "/domain/overview") {
        await this.$router.push("/domain/overview");
      }
    },
  },
  async created() {
    this.clearCache();
    const userLinksValue = [
      {
        ...USER_LINKS,
        title: this.$t(PRODUCTS.userLinksTitle),
      },
    ];
    this.setUserLinks(userLinksValue);
  },
  beforeMount() {
    let parentPath = "";
    if (this.selectedAccount?.account_type === ACCOUNT_TYPE.SYSTEM) {
      parentPath += "/system";
    }
    if (this.selectedAccount?.account_type <= ACCOUNT_TYPE.MSP) {
      parentPath += "/msp";
    }
    this.setParentPath(parentPath);
  },
  mounted() {
    this.fetchSupportData();
    this.fetchNewDomains();
  },
  beforeUnmount() {
    this.clearSearch();
  },
};
</script>
