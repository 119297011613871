<template>
  <div class="tw-flex tw-h-screen tw-overflow-hidden">
    <t-side-nav :navItems="navItems">
      <template v-slot:dropdown="{ isClosed }" v-if="usersList.length">
        <t-nav-dropdown
          data-test-id="navbar-user-select"
          @selected="userSelected"
          :loading="loading"
          :isClosed="isClosed"
          :options="usersList"
          :initial-selected="initialUser"
        />
      </template>
    </t-side-nav>
    <div
      class="tw-ml-14 tw-flex tw-flex-1 tw-flex-col tw-overflow-y-auto tw-bg-light lg:tw-ml-0"
    >
      <main class="tw-mb-6">
        <div class="container-fluid">
          <t-page-header :user="user" :user-links="userLinks" />
          <router-view />
        </div>
      </main>
      <cl-footer
        class="tw-mt-auto"
        v-if="logo"
        :version="appVersion"
        :logo="logo"
      />
      <div v-else role="status" class="tw-animate-pulse">
        <div
          class="tw-bg-gray-20 tw-h-20 tw-w-full tw-bg-dorian-gray-100"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { version } from "../../../package";

import TSideNav from "@/components/TSideNav";
import { ACCOUNT_TYPE, TIER, USER_LINKS, PRODUCTS } from "@/constants";
import { setSupportLinks } from "@/utils/set-layout-support.js";
import TPageHeader from "@/components/TPageHeader/TPageHeader.vue";
import TNavDropdown from "@/components/TNavDropdown";

export default {
  name: "DomainLayout",
  components: {
    TSideNav,
    TPageHeader,
    TNavDropdown,
  },
  data() {
    return {
      breakpointXL: null,
      closed: false,
      loading: true,
      account_type: ACCOUNT_TYPE.DOMAIN,
      navItems: this.$getPrimaryNavItems("domain_admin"),
      appVersion: version,
      initialUser: null,
      usersList: [],
    };
  },
  computed: {
    ...mapGetters("authentication", [
      "authUser",
      "selectedAccount",
      "selectedDomain",
      "selectedCustomer",
    ]),
    ...mapGetters("userInterface", ["interface", "uiLogo"]),
    ...mapGetters("support", ["support", "userLinks"]),
    ...mapGetters("users", ["userAccounts"]),

    user() {
      const email = this.authUser?.email ?? null;
      const accountname = this.authUser?.accountname ?? null;

      return {
        email,
        accountname,
      };
    },
    logo() {
      return this.uiLogo;
    },
  },
  watch: {
    usersList() {
      this.setSelectedUser();
    },
    support: {
      handler(support) {
        const res = setSupportLinks(support);
        const resWithTranslatedTitle = res.map((t) => ({
          ...t,
          title: this.$t(t.title),
        }));
        this.updateUserLinks(resWithTranslatedTitle);
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("authentication", ["setUser"]),
    ...mapActions("userInterface", ["fetchInterface"]),
    ...mapActions("pageMeta", ["setParentPath"]),
    ...mapActions("support", [
      "fetchSupport",
      "setUserLinks",
      "updateUserLinks",
    ]),
    ...mapActions("emailsHistory", ["clearSearch"]),

    setSelectedDomain() {
      this.initialDomain = 0;
    },

    async userSelected(user) {
      this.setUser(user);
      if (user.allDomains) {
        this.$router.push("/customer/overview", undefined, { shallow: true });
      } else if (user.allUsers) {
        this.$router.push("/domain/overview", undefined, { shallow: true });
      } else if (this.$route.path !== "/user") {
        this.$router.push("/", undefined, { shallow: true });
      }
    },
    setSelectedUser() {
      this.initialUser = 0;
    },
    setUserList(domain) {
      let otherAccounts = [
        {
          name: domain,
          short: domain.charAt(0),
          id: 0,
        },
      ];
      if (this.selectedAccount?.account_type <= ACCOUNT_TYPE.CUSTOMER) {
        otherAccounts.push({
          name: "All Domains",
          short: "AD",
          allDomains: true,
        });
      } else {
        otherAccounts.push({
          name: "Logout",
          short: "LO",
          logOut: true,
        });
      }

      return [...otherAccounts];
    },
    async fetchSupportApi() {
      try {
        await this.fetchSupport({
          type_id: this.selectedDomain.id,
          type: TIER.domains,
        });
      } catch (_err) {
        // stub
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    const userLinksValue = [
      {
        ...USER_LINKS,
        title: this.$t(PRODUCTS.userLinksTitle),
      },
    ];
    this.setUserLinks(userLinksValue);
  },
  mounted() {
    if (!this.selectedDomain) {
      return;
    }

    let parentPath = "";
    if (this.selectedAccount?.account_type === ACCOUNT_TYPE.SYSTEM) {
      parentPath += "/system";
    }
    if (this.selectedAccount?.account_type <= ACCOUNT_TYPE.MSP) {
      parentPath += "/msp";
    }
    if (this.selectedAccount?.account_type <= ACCOUNT_TYPE.CUSTOMER) {
      parentPath += "/customer";
    }
    this.setParentPath(parentPath);

    this.fetchSupportApi();

    if (this.selectedAccount?.account_type_name === "domain") {
      const domain = this.selectedDomain.name || this.selectedDomain.domain;
      this.usersList = this.setUserList(domain);
    } else {
      const domain = this.selectedDomain.domain;
      this.usersList = this.setUserList(domain);
    }
  },
  beforeUnmount() {
    this.clearSearch();
  },
};
</script>
